var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "history-item" }, [
    _c(
      "div",
      { staticClass: "timestamp-container" },
      [
        _c("span", [_vm._v(_vm._s(_vm.getDateString()))]),
        _c("font-awesome-icon", {
          staticClass: "timestamp-icon",
          attrs: { icon: ["far", "clock"] },
        }),
      ],
      1
    ),
    _c(
      "ul",
      { staticClass: "history-detail" },
      [
        _vm._l(_vm.events, function (event, index) {
          return [
            _c("li", { key: index }, [
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$sanitize(event, {
                      allowedTags: ["div", "strong", "ul", "li"],
                    })
                  ),
                },
              }),
            ]),
          ]
        }),
      ],
      2
    ),
    _c("div", { staticClass: "author-container" }, [
      _c(
        "div",
        { staticClass: "author-name" },
        [
          _c("span", [_vm._v(_vm._s(_vm.author))]),
          _c("font-awesome-icon", {
            staticClass: "author-icon",
            attrs: { icon: ["far", "user"] },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }