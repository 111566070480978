<template>
  <b-modal v-model="modalShow" size="lg" @shown="initModal" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow"
      @hidden="hidden"
      scrollable>

    <ag-grid-vue :style="{ width, height }" class="ag-theme-balham" id="enum-history-grid"
      :gridOptions="gridOptions"
      @grid-ready="onGridReady"
      :columnDefs="columnDefs"
      :context="context"
      :defaultColDef="defaultColDef"
      :getRowId="params => params.data.id"
      :overlayNoRowsTemplate="overlayNoRowsTemplate"
      :overlayLoadingTemplate="overlayLoadingTemplate"
      
      rowModelType="serverSide"
      :serverSideInfiniteScroll="true"
      :sideBar="false"
      suppressDragLeaveHidesColumns
      suppressContextMenu
      suppressMultiSort
      suppressRowDeselection
      @model-updated="onSizeToFit"
      :getRowHeight="getRowHeight"
      >
    </ag-grid-vue>

    <template v-slot:modal-footer="{ cancel }">
      <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.close') }}</b-button>
    </template>
  </b-modal>
</template>

<script>
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import EnumHistoryDetailCellRenderer from '@/components/Aggrid/CellRenderer/EnumHistoryDetail';
import { enumService } from '@/services';

function ServerSideDatasource(self) {
  return {
    getRows(params) {
      self.$nextTick(() => {
        self.gridApi.hideOverlay();
        self.gridApi.showLoadingOverlay();
      });
      if (self.enumType == null) {
        params.success({ rowData: [], rowCount: 0 });
        self.gridApi.hideOverlay();
        return;
      }
      enumService.history(self.enumType, self, self.buildParams(params)).then((response) => {
        self.totalRecords = response.arg_total;
        const rowData = response.data.core;
        params.success({ rowData, rowCount: response.arg_total });
        self.gridApi.hideOverlay();
      })
      .catch(function(error) {
        console.error(error); // eslint-disable-line no-console
        params.failCallback();
        self.gridApi.hideOverlay();
        self.$nextTick(() => {
          self.gridApi.showLoadingOverlay();
          document.getElementById('history-grid-loading-overlay').innerHTML = self.$t('error.grid_data_loading');
        });
      });
    }
  }
}

export default {
  name: 'EnumHistory'
  , components: {
    'ag-grid-vue': AgGridVue
    , historyDetailCellRenderer: EnumHistoryDetailCellRenderer //eslint-disable-line vue/no-unused-components
  }
  , props: {
    show: {
      type: Boolean
      , required: true
    }
    , title: {
      type: String
      , default: null
    }
    , enumType: {
      type: String
      , default: null
    }
  }
  , data: function() {
    return {
      gridOptions: null
      , gridApi: null
      , columnDefs: null
      , context: null
      , defaultColDef: null
      , modalShow: false

      , isManualTriggered: false
      , height: 'calc(100vh - 210px)'
      , width: '100%'
      , descriptionColWidth: 0
      , scrollbarWidth: -1
      , columnWidth: -1
    }
  }
  , created() {
    if(this.gridApi) {
      this.gridApi.showLoadingOverlay();
    }
  }
  , beforeMount() {
    const mode = this.getResolutionMode();
    this.calcHeight(mode);
    const self = this;
    this.gridOptions = {
      headerHeight: 0
      , onColumnResized: function(event) {
        if (self.columnWidth != event.column.actualWidth) {
          self.columnWidth = event.column.actualWidth;
          const mode = self.getResolutionMode();
          self.calcHeight(mode);
          event.api.forEachNode((node) => {
            const newHeight = self.getRowHeight(node);
            node.setRowHeight(newHeight);
          });
          event.api.onRowHeightChanged();
        }
      }
    };
    this.columnDefs = [
      {
        field: 'description'
        , sortable: false
        , cellRenderer: 'historyDetailCellRenderer'
        , minWidth: 200
      }
    ];
    this.defaultColDef = {
      sortable: true
      , resizable: true
      , hide: false
      , suppressHeaderMenuButton: true
    };
    this.context = {
      componentParent: self
    };
  }
  , mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    this.modalShow = this.show;
  }
  , beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
    this.gridApi = null;
  }
  , watch: {
    show(newValue) {
      //When modal is hidden/closed, grid is destroyed. All the references become obsolete and should be released to avoid memory leak.
      if(!newValue) {
        this.gridApi = null;
      }
      if(newValue != this.modalShow) {
        this.modalShow = newValue;
        if(this.gridApi != null) {
          this.gridApi.refreshServerSide({ purge: true }); //Force reloading data from server.
        }
      }
      
    }
  }
  , computed: {
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>${ this.$t('error.grid_data_loading') }</span>`;
    },
    overlayLoadingTemplate() {
      return `<span class='grid-overlay' id="history-grid-loading-overlay">${ this.$t('history.grid.loading') }</span>`;
    },
    labelTitle() {
        return this.title != null? this.title : this.enumType != null? this.$t('enum.history_title', [this.$t(`enum.${this.enumType}`)]) : this.$t('history');
    }
  }
  , methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      
      const self = this;
      const updateData = () => {
        params.api.setGridOption('serverSideDatasource', new ServerSideDatasource(self));
      };

      updateData();
    }
    , onSizeToFit() {
      if (this.gridApi) {
        this.calcColumnWidth();
      }
    }
    , buildParams({ request: {/** sortModel, */endRow, startRow} }) {
      const params = {
        start: startRow,
        limit: endRow - startRow + 1,
      };
      return params;
    }
    , initModal() {

    }
    , hidden() {
      this.$emit('update:show', false);
    }
    , getRowHeight(params) {
      // This method is created for Ag Grid getRowHeight(params), initially. 
      // It is later used/called/referred in onColumnResize event. 
      // Although different object is passed in as parameter but the object has data properties.
      const columnWidth = this.gridApi.getColumn('description').actualWidth;
      if(this.scrollbarWidth < 0) {
        this.scrollbarWidth = this.getScrollbarWidth();
      }

      const reservedWidth = 64 + this.scrollbarWidth; //Left and right padding: 22px; border size: 2px; list styling left padding: 40px;

      let height = 0;
     
      if(params && params.data && params.data.events) {
        const events = params.data.events;
        events.forEach(value => {
          const p = document.createElement('p');
          p.style.width = `${columnWidth - reservedWidth}px`; 
          p.innerHTML = value;
          p.style.fontSize  = '14px';
          p.style.visibility = 'hidden';
          p.style.lineHeight = '20px';
          p.style.marginBottom = '0px';
          p.style.paddingBottom = '0px';
          p.style.wordBreak = 'break-word';
          document.body.appendChild(p);
          height += p.offsetHeight;
          p.remove();
          height += 5;
        });
      }

      height += 94; //Timestamp: 36px; author: 36px; padding-top: 10px; padding-bottom 10px; border size: 2px;

      return height;
    }
    , calcHeight(mode) {
      const isDocHeightLonger = document.body.scrollHeight - window.innerHeight > 0;
      if(isDocHeightLonger) {
        if(mode == 'xs') {
          const newHeight =  `${window.innerHeight - 170}px`;
          if(newHeight != this.height) {
            this.height = newHeight;
          }
        } else {
          const newHeight =  `${window.innerHeight - 210}px`;
          if(newHeight != this.height) {
            this.height = newHeight;
          }
        }
      } else {
        const newHeight = 'calc(100vh - 210px)'
        if(newHeight != this.height) {
          this.height = newHeight;
        }
      }
    }
    , calcColumnWidth() {
      if(this.gridApi) {
        const columnState =this.gridApi.getColumnState();
        if(columnState != null) {
          const authorColState = columnState.find(i => i.colId == 'description');
          authorColState.flex = 1;
          this.gridApi.applyColumnState({ state: columnState });
        }
      }
    }
    , calcDescColumnWidth(mode) {
      const screenWidth = window.innerWidth;
      if(mode == 'xs') {
        const gridWidth = screenWidth - 50;// screenWidth - 32 - 18 //Left and right padding: 32px; Space between modal and window: 18px.
        this.gridApi.setColumnWidth('description', gridWidth);
        this.descriptionColWidth = gridWidth;
        return { mode }
      } else if (mode == 'sm' || mode == 'md') {
        const gridWidth = 466; // 498 - 32; //Modal: 498px, left and right padding: 32px;
        this.gridApi.setColumnWidth('description', gridWidth);
        this.descriptionColWidth = gridWidth;
        return { mode }
      } else { //Large Screen break point
        const gridWidth = 766; // 798 - 32; //Modal: 798px, left and right padding: 32px;
        const fixWidth = 500;
        this.gridApi.setColumnWidth('description', fixWidth);
        this.descriptionColWidth = fixWidth;
        return { mode, remainingWidth: gridWidth - fixWidth } // RemainingWidth: 266
      }
    }
    , onResize() {
      this.calcColumnWidth();
    }
    , getResolutionMode() {
      const screenWidth = window.innerWidth;
      //Bootstraps break points are referred.
      const smallDeviceBreakPoint = 575;
      const mediumDeviceBreakPoint = 768;
      const largeDeviceBreakPoint = 991;
      const extraLargeDeviceBreakPoint = 1199;
      if (screenWidth <= smallDeviceBreakPoint) {
        return 'xs';
      } else if (screenWidth <= mediumDeviceBreakPoint) {
        return 'sm';
      } else if (screenWidth <= largeDeviceBreakPoint) {
        return 'md';
      } else if (screenWidth <= extraLargeDeviceBreakPoint) {
        return 'lg';
      } else { // extra large
        return 'xl';
      }
    }
    , getScrollbarWidth() {
      if(document == null) {
        return 0;
      }

      // Creating invisible container
      const outer = document.createElement('div');
      outer.style.visibility = 'hidden';
      outer.style.overflow = 'scroll'; // forcing scrollbar to appear
      outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
      document.body.appendChild(outer);

      // Creating inner element and placing it in the container
      const inner = document.createElement('div');
      outer.appendChild(inner);

      // Calculating difference between container's full width and the child width
      const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

      // Removing temporary elements from the DOM
      outer.parentNode.removeChild(outer);

      return scrollbarWidth;
    }
  }

}
</script>