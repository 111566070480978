var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        size: "lg",
        title: _vm.labelTitle,
        "footer-class": "footerClass",
        "no-close-on-backdrop": "",
        "content-class": "shadow",
        scrollable: "",
      },
      on: { shown: _vm.initModal, hidden: _vm.hidden },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function ({ cancel }) {
            return [
              _c(
                "b-button",
                {
                  attrs: { size: "sm", variant: "danger" },
                  on: {
                    click: function ($event) {
                      return cancel()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("button.close")))]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.modalShow,
        callback: function ($$v) {
          _vm.modalShow = $$v
        },
        expression: "modalShow",
      },
    },
    [
      _c("ag-grid-vue", {
        staticClass: "ag-theme-balham",
        style: { width: _vm.width, height: _vm.height },
        attrs: {
          id: "enum-history-grid",
          gridOptions: _vm.gridOptions,
          columnDefs: _vm.columnDefs,
          context: _vm.context,
          defaultColDef: _vm.defaultColDef,
          getRowId: (params) => params.data.id,
          overlayNoRowsTemplate: _vm.overlayNoRowsTemplate,
          overlayLoadingTemplate: _vm.overlayLoadingTemplate,
          rowModelType: "serverSide",
          serverSideInfiniteScroll: true,
          sideBar: false,
          suppressDragLeaveHidesColumns: "",
          suppressContextMenu: "",
          suppressMultiSort: "",
          suppressRowDeselection: "",
          getRowHeight: _vm.getRowHeight,
        },
        on: { "grid-ready": _vm.onGridReady, "model-updated": _vm.onSizeToFit },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }